import {useEffect} from "react";
import {Link} from "react-router-dom";
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

const LightBox = ({children, closePath, padding, centered}) => {

    useEffect(() => {
        document.body.style.overflow = 'hidden';
        return ()=> document.body.style.overflow = 'unset';
    }, []);

    return (
        <Wrapper centered={centered}>
            <Layout centered={centered}>
                <Header>
                    <CloseButton to={closePath} preventScrollReset={true}><i className="fal fa-times-circle"/></CloseButton>
                </Header>
                <Content padding={padding}>
                    {children}
                </Content>
            </Layout>
        </Wrapper>
    )
}

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  min-height: 100%;
  background-color: #ffffffbb;
  display: flex;
  justify-content: center;
  align-items: ${props => props.centered ? 'center' : 'unset'};
  overflow: hidden;
  z-index: 1;
`;

const Layout = styled.div`
  position: absolute;
  width: 800px;
  ${breakpoint('xl')` // 24
    width: 1000px;
  `}
  height: ${props => props.centered ? 'unset' : 'calc(100%)'};
  background-color: white;
  box-shadow: 0 0 1rem #00000030;
  margin: 0 0 7rem 0;
  border-radius: 10px 10px 0 0;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const Header = styled.div`
  padding: .5rem 1rem 0 1rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: ${props => props.padding ? '0 5rem 3rem 5rem' : 'unset' };
  gap: ${props => props.padding ? '2rem' : 'unset' };
  overflow: scroll;
`;

const CloseButton = styled(Link)`
  font-family: "Lato", sans-serif;
  color: #796e65;
  font-weight: 700;
  font-size: 2rem;
`;



export default LightBox;
