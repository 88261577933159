import ReactMarkdown from "react-markdown";
import styled from "styled-components";

const Markdown = styled(ReactMarkdown)`
    h1 {
      color: #FDB415;
      font-size: 2.5rem;
      margin-bottom: 1.5rem;
    }
  
    h2 {
      color: #796e65;
      font-size: 2rem;
      margin: 2.5rem 0 1.5rem 0;
    }
  
    h3 {
      color: #FDB415;
      font-weight: bold;
      font-size: 1.5rem;
      white-space: pre-line;
      vertical-align: bottom;
      margin: 1.5rem 0;
      text-transform: uppercase;
    }
  
    strong {
      color: #796e65;
    }
  
    p {
      white-space: pre-line;
      vertical-align: bottom;
      margin-bottom: 1.5rem;
    }  
  
    ul {
      list-style-type: none;
      margin: 0;
      padding: 0 0 1.5rem 2rem;

      li::before {
        content: "\\2022"; /* Add content: \\2022 is the CSS Code/unicode for a bullet */
        color: #FDB415; /* Change the color */
        font-weight: bold; /* If you want it to be bold */
        display: inline-block; /* Needed to add space between the bullet and the text */
        width: 1em; /* Also needed for space (tweak if needed) */
        margin-left: -1em; /* Also needed for space (tweak if needed) */
      }      
      li {
          p {
            margin: 0;
            display: inline;
          }
      }
    }
`;

export default Markdown;
