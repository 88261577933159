import {all} from 'redux-saga/effects';
import createSagaMiddleware from 'redux-saga'
import {configureStore} from '@reduxjs/toolkit';
import reducers from "./reducers";
import configuration from './configuration/saga';
import news from './news/saga';
import mediaHub from './media-hub/saga';

const sagaMiddleWare = createSagaMiddleware();

const store = configureStore({
    reducer: reducers,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(sagaMiddleWare),
})

function* initSagas() {
    yield all([
        configuration(),
        mediaHub(),
        news()
    ])
}

sagaMiddleWare.run(initSagas)

export default store;
