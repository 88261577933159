import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import styled from "styled-components";
import Center from '../../components/center';
import Layout from "../../components/layout";
import MediaContent from "../../components/media-hub/media-content";
import MediaList from "../../components/media-hub/media-list";
import SectionHeader from "../../components/section-header";
import SideBar from "../../components/side-bar";
import useImagePreloader from "../../hooks/useImagePreloader";
import {API} from "../../store/constants";
import {loadContents, loadNavigation} from "../../store/media-hub/actions";

const imageSrcList = [
    "/images/mediahub-image.jpg",
]

const Media = () => {

    const {imagesLoaded} = useImagePreloader(imageSrcList)
    const dispatch = useDispatch();

    const navigate = useNavigate();
    const {sectionId, itemId, mediaId} = useParams();

    const {items, initialSectionId, initialItemId, content} = useSelector(state => {
        const {mediaHub: {items, initialSectionId, initialItemId, content}} = state;
        return {items, initialSectionId, initialItemId, content};
    });

    const setPath = (selected) => {
        navigate(`/mediahub/${selected[0]}/${selected[1]}#content`);
    }

    useEffect(() => {
        dispatch(loadNavigation())
    }, []);

    useEffect(() => {
        if (items && items.length && initialSectionId && initialItemId) {
            if (sectionId && itemId) {
                dispatch(loadContents(itemId));
            } else {
                dispatch(loadContents(initialItemId));
            }
        }
    }, [itemId, initialItemId])

    const getPosterImageFilename = (url) => {
        return `${url.split(".").shift()}.jpg`;
    }

    let item = null;
    let videoUrl = null;
    let coverUrl = null;
    let title = null;
    if(items && items.length > 0) {
        item = items.find(item => item.id === (sectionId || initialSectionId)).children.find(child => child.id === (itemId || initialItemId));
    }
    if(item) {
        videoUrl = item.video;
        coverUrl = item.cover;
    }
    const itemContent = content && mediaId ? content.find(item => item.id === mediaId) : null;

    return (
        <Center hidden={!imagesLoaded}>
            <SectionHeader>
                <img src="/images/mediahub-image.jpg" alt="Media Hub Header Image"/>
            </SectionHeader>
            <div style={{backgroundColor: 'red'}} id="content" />
            {
                items && initialSectionId && initialItemId ?
                    <Layout hidden={!items} sideBar={
                        <SideBar items={items}
                                 selected={[sectionId || initialSectionId, itemId || initialItemId]}
                                 setSelected={setPath}
                        />
                    }>
                        {
                            videoUrl ? <Video
                                src={`${API.URL}${videoUrl}`}
                                controls
                                poster={`${API.URL}${coverUrl}`}
                                key={videoUrl}>
                                Sorry, your browser doesn't support embedded videos,
                                but don't worry, you can <a href={videoUrl}>download it</a>
                                and watch it with your favorite video player!
                            </Video> : null
                        }
                        {
                            content ?
                                <MediaList items={content}
                                           path={[sectionId || initialSectionId, itemId || initialItemId]}/>
                                : null
                        }
                        {
                            mediaId && itemContent ?
                                <MediaContent
                                    closePath={`/mediahub/${sectionId || initialSectionId}/${itemId || initialItemId}`}
                                    path={`/data/mediahub/${sectionId || initialSectionId}/${itemId || initialItemId}`}
                                    content={itemContent}/>
                                : null
                        }
                    </Layout>
                    : null
            }
        </Center>
    )
}

const Video = styled.video`
  display: flex;
  width: 66%;
  border-radius: 10px;
  margin-bottom: 2rem;
`;

export default Media;
