import {MEDIA_HUB} from "../../constants";

const loadNavigation = () => (
    {
        type: MEDIA_HUB.LOAD_NAV,
    }
)

const loadContents = (categoryId) => (
    {
        type: MEDIA_HUB.LOAD_CONTENT,
        payload: categoryId,
    }
)

const loadItem = (itemId) => (
    {
        type: MEDIA_HUB.LOAD_ITEM,
        payload: itemId,
    }
)

export {loadNavigation, loadContents, loadItem};
