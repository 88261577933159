import styled from 'styled-components';

const SectionHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  z-index: -1;
  position: sticky;
  top: 0;
  
  img {
    width: 100%;
  }
`;

export default SectionHeader;
