import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import BackButton from "../layout/back-button";

const SideBar = ({items, selected, setSelected, initialChildSelected = true}) => (
    <Wrapper>
        <BackButton/>
        <List>
            {
                items.map((item) => {
                    const {id: sectionId, title, children} = item;
                    let initialItemId = "";
                    if(children && children.length && initialChildSelected) {
                        initialItemId = children[0].id;
                    }
                    return (
                        <ListItem key={sectionId}>
                            <Button onClick={() => setSelected([sectionId, initialItemId])} selected={selected[0] === sectionId}>{title}</Button>
                            {
                                selected[0] === sectionId && children !== undefined ?
                                    <List>
                                        {
                                            children.map((child) => {
                                                const {id, title} = child;
                                                return (
                                                    <ListItem key={id}>
                                                        <Text selected={selected[1] === id}
                                                              onClick={() => setSelected([sectionId, id])}>{title}</Text>
                                                    </ListItem>
                                                )
                                            })
                                        }
                                    </List>
                                    : null
                            }
                        </ListItem>
                    )
                })
            }
        </List>
    </Wrapper>
)

const Wrapper = styled.div`
  padding: 1rem 1rem 0 0;
  ${breakpoint('lg')` // 24
    padding: 1rem 2rem 0 0;
  `}
  ${breakpoint('xl')` // 24
    padding: 1rem 3rem 0 0;
  `}
  position: sticky;
  top: 1rem;
`;

const List = styled.ul`
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  list-style: none;
`;

const ListItem = styled.li`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Button = styled.div`
  display: flex;
  justify-content: center;
  font-family: "AlternateGotNo2D", sans-serif;
  font-size: 1.1rem;
  font-weight: 400;
  color: white;
  //color: ${props => props.selected ? 'white' : '#796e65'};
  text-decoration: none;
  text-transform: uppercase;
  border-radius: 10px;
  background-color: ${props => props.selected ? '#009b77' : '#aaaaaa'};
  padding: 0.6rem 0 0.3rem 0;
  margin: 0.25rem 0;

  text-align: center;
  cursor: pointer;
  
  &:hover {
    background-color: #009b77;
    color: white;
  }
`;

const Text = styled.div`
  display: flex;
  justify-content: center;
  font-family: "AlternateGotNo2D", sans-serif;
  font-size: 1.1rem;
  font-weight: 400;
  color: ${props => props.selected ? '#009b77' : '#796e65'};
  text-decoration: none;
  text-transform: uppercase;
  padding: 0.6rem 0 0.3rem 0;
  text-align: center;
  cursor: pointer;

  &:hover {
    color: #009b77;
  }
`;

export default SideBar;
