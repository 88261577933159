import React, {Fragment} from 'react';
import ReactDOM from 'react-dom/client';
import {Provider} from 'react-redux';
import {createBrowserRouter, createRoutesFromElements, Route, RouterProvider} from "react-router-dom";
import {ThemeProvider} from "styled-components";
import App from './App';
import Layout from "./container/layout";
import './fa.css';
import './index.css';
import store from './store';
import Media from './views/media';
import Networking from "./views/networking";
import News from './views/news';

const theme = {
    breakpoints: {
        xs: 0,
        sm: 576,
        md: 768,
        lg: 992,
        xl: 1200,
    }
}

const router = createBrowserRouter(
    createRoutesFromElements(
        <Fragment>
            <Route exact path="/" element={<App/>}/>
            <Route element={<Layout/>}>
                <Route path="update" element={<News/>}>
                    <Route path=":sectionId" element={<News/>}/>
                    <Route path=":sectionId/:detailsId" element={<News/>}/>
                    <Route path=":sectionId/:detailsId/:mediaId" element={<News/>}/>
                    <Route index element={<News/>}/>
                </Route>
                <Route path="mediahub" element={<Media/>}>
                    <Route path=":sectionId" element={<Media/>}/>
                    <Route path=":sectionId/:itemId" element={<Media/>}/>
                    <Route path=":sectionId/:itemId" element={<Media/>}/>
                    <Route path=":sectionId/:itemId/:mediaId" element={<Media/>}/>
                    <Route index element={<Media/>}/>
                </Route>
                <Route path="networking" element={<Networking/>}>
                    <Route index element={<Networking/>}/>
                </Route>
            </Route>
        </Fragment>
    )
)

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <Provider store={store}>
            <ThemeProvider theme={theme}>
                <RouterProvider router={router}/>
            </ThemeProvider>
        </Provider>
    </React.StrictMode>
);
