import {LOADING} from "../../constants";

const startLoading = () => (
    {
        type: LOADING.START
    }
)

const stopLoading = (error = false) => {
    if(!error) {
        return {
            type: LOADING.SUCCESS
        }
    } else {
        const {request: {responseURL: url}, message, config: {method}} = error;
        return {
            type: LOADING.ERROR,
            payload: {
                url,
                method,
                message,
            }
        }
    }
}

export {startLoading, stopLoading};
