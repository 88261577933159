import {useState} from "react";
import {Link} from "react-router-dom";
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import {API} from "../../../store/constants";

const MediaList = ({items, path}) => {
    const [selected, setSelected] = useState(null);
    return (
        <Grid>
            {
                items.map(item => {
                    const {thumbnail: image, title, id, locked} = item;
                    return (
                        !locked ?
                            <Item key={image}
                                  preventScrollReset={true}
                                  to={`/mediahub/${path[0]}/${path[1]}/${id}`}
                                  onMouseOver={() => setSelected(image)}
                                  onMouseOut={() => setSelected(null)}
                            >
                                <ImageContainer selected={selected === image}>
                                    <Image key={image} src={`${API.URL}${image}`} path={path}/>
                                </ImageContainer>
                                <Text>{title}</Text>
                            </Item> :
                            <NonClickable key={image}>
                                <ImageContainer selected={selected === image}>
                                    <Image key={image} src={`${API.URL}${image}`} path={path} dimmed />
                                    <PadLock className="far fa-lock"/>
                                </ImageContainer>
                                <Text>{title}</Text>
                            </NonClickable>
                    )
                })
            }
        </Grid>
    )
}

const PadLock = styled.i`
  position: absolute;
  font-size: 1.1rem;
  border-radius: 3rem;
  background-color: #ffffffee;
  padding: 1rem;
`;

const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 15px 15px;
  padding: 0.25rem 0 0 0;
`;

const Item = styled(Link)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  box-sizing: border-box;
  width: calc(50% - 10px);
  ${breakpoint('xl')` // 24
    width: calc(33.3333333% - 10px);
  `}  
`;

const NonClickable = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  box-sizing: border-box;
  width: calc(50% - 10px);
  ${breakpoint('xl')` // 24
    width: calc(33.3333333% - 10px);
  `}
  color: #796e65;
`;

const ImageContainer = styled.div`
  position: relative;
  aspect-ratio: 4 / 3;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex: 1;
  border-radius: 10px;
  overflow: hidden;
  background-color: #dddddd;
  padding: ${props => props.selected ? "0.25rem" : "0.75rem"};
  transition: 0.25s ease-out;
`;

const Image = styled.div`
  background: url('${props => props.src}');
  background-size: contain;
  //background-size: ${props => props.selected ? "100%" : "95%"};
  background-position: center;
  background-repeat: no-repeat;
  opacity: ${props => props.dimmed ? 0.25 : 1};
  width: 100%;
  height: 100%;
  transition: 0.25s ease-out;
`;

const Text = styled.p`
  font-family: "AlternateGotNo2D", sans-serif;
  font-size: 1.1rem;
  font-weight: 400;
  text-transform: uppercase;
  text-align: center;
  margin: 0.5rem 0;
  //color: #796e65;
`;

export default MediaList;
