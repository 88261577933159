import styled, {keyframes} from 'styled-components';

const Center = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  visibility: ${props => props.hidden ? 'hidden' : 'visible'};
  animation: ${props => props.hidden ? fadeOut : fadeIn} .2s linear;
  transition: visibility .2s linear;
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
`;

export default Center;
