import { combineReducers } from '@reduxjs/toolkit';
import news from './news/reducer';
import mediaHub from './media-hub/reducer';
import loading from './loading/reducer';

const reducers = combineReducers({
    mediaHub,
    news,
    loading,
});

export default reducers;
