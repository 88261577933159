import axios from "axios";
import {call, put, takeEvery} from 'redux-saga/effects';
import {API, MEDIA_HUB} from "../../constants";
import {startLoading, stopLoading} from "../../loading/actions";

axios.defaults.headers.common['accept'] = `application/json`;

function* loadNav(action) {
    try {
        yield put(startLoading());
        const topics = yield call(axios, `${API.URL}${API.MEDIA_HUB_NAV_PATH}`);
        const {data} = topics;
        yield put({type: MEDIA_HUB.LOAD_NAV_SUCCESS, payload: data});
        yield put(stopLoading());
    } catch(e) {
        console.log(e);
        yield put({type: MEDIA_HUB.LOAD_NAV_ERROR});
        yield put(stopLoading(e));
    }
}

function* loadContent(action) {
    const {payload: categoryId} = action;
    try {
        yield put(startLoading());
        const topics = yield call(axios, `${API.URL}${API.MEDIA_HUB_ITEMS_PATH}${categoryId}`);
        const {data} = topics;
        yield put({type: MEDIA_HUB.LOAD_CONTENT_SUCCESS, payload: data});
        yield put(stopLoading());
    } catch(e) {
        console.log(e);
        yield put({type: MEDIA_HUB.LOAD_CONTENT_ERROR});
        yield put(stopLoading(e));
    }
}

function* loadItem(action) {
    const {payload: itemId} = action;
    try {
        yield put(startLoading());
        const item = yield call(axios, `${API.URL}${API.MEDIA_HUB_ITEM_PATH}${itemId}${API.MEDIA_HUB_ITEM_PARAMS}`);
        const {data} = item;
        yield put({type: MEDIA_HUB.LOAD_ITEM_SUCCESS, payload: data});
        yield put(stopLoading());
    } catch(e) {
        console.log(e);
        yield put({type: MEDIA_HUB.LOAD_ITEM_ERROR});
        yield put(stopLoading(e));
    }
}

function* initWatchers() {
    yield takeEvery(MEDIA_HUB.LOAD_NAV, loadNav);
    yield takeEvery(MEDIA_HUB.LOAD_CONTENT, loadContent);
    yield takeEvery(MEDIA_HUB.LOAD_ITEM, loadItem)
}

export default initWatchers;
