import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import {API} from "../../../store/constants";
import LightBox from "../../light-box";
import Markdown from "../../markdown";
import LinkRenderer from "../../markdown/LinkRenderer";

const MediaContent = ({closePath, id, content}) => {
    const {title, description, image, elements} = content;
    return (
        <LightBox closePath={closePath} padding={true}>
            <h1>{title}</h1>
            <Markdown components={{a: LinkRenderer}}>{description}</Markdown>
            <Cover image={`${API.URL}${image}`}/>
            {
                elements ?
                    <Elements>
                        {
                            elements.map(el => renderElement({el}))
                        }
                    </Elements>
                    : null
            }
        </LightBox>
    )
}

const renderElement = ({el}) => {
    const {type, url, title, cover} = el;
    switch (type) {
        case "video":
            return (
                <Video src={`${API.URL}${url}`}
                       controls
                       poster={`${API.URL}${cover}`}
                       key={url}>
                    Sorry, your browser doesn't support embedded videos,
                    but don't worry, you can <a href={url}>download it</a>
                    and watch it with your favorite video player!
                </Video>
            )
        case "link":
            return (
                <ExternalLink href={url} key={url} target="_blank" rel="noreferrer"><Icon className="far fa-link"/> {title}</ExternalLink>
            )
        default:
            return null;
    }
}

const Header = styled.div`
  display: flex;
  flex-direction: row;
  gap: 2rem;
`;

const Cover = styled.div`
  aspect-ratio: 4 / 3;
  width: 100%;
  min-height: 480px;
  ${breakpoint('xl')` // 24
    min-height: 630px;
  `}  
  flex: 1;
  background: url('${props => props.image}');
  background-size: contain;
  background-color: #dddddd;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 10px;
  border-width: 1rem;
  border-style: solid;
  border-color: #dddddd;
`;

const Description = styled.div`
  flex: 1;
`;

const Elements = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const Video = styled.video`
  display: flex;
  width: 100%;
  border-radius: 10px;
`;

const ExternalLink = styled.a`
  font-family: "AlternateGotNo2D", sans-serif;
  font-size: 1.1rem;
  font-weight: 400;
  text-transform: uppercase;
  margin-top: 0.25rem;
`;

const Icon = styled.i`
  background-color: #009b77;
  color: white;
  padding: .5rem;
  border-radius: 1rem;
  margin-right: .25rem;
`;

export default MediaContent;
