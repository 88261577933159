import styled from "styled-components";
import {Link} from "react-router-dom";

const StyledLink = styled(Link)`
  font-family: "AlternateGotNo2D", sans-serif;
  font-size: 1.1rem;
  font-weight: 400;
  text-transform: uppercase;
  display: inline-block;
  flex-direction: row;
  height: 30px;

  &:hover {
    color: #009b77;
  }
`;

const Icon = styled.i`
  padding-right: 0.25rem;
  
`;

const BackButton = ({to, className}) => (
    <StyledLink to="/">
        <Icon className="far fa-chevron-left"/><span>Back</span>
    </StyledLink>
)

export default BackButton;
