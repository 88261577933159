const isLocal = window.location.hostname === 'localhost';
const API_URL = isLocal ? 'https://api.dupixent-derm.apps.stage.launchpharm.cloud' : `https://api.${window.location.hostname}`;
//const API_URL = isLocal ? 'http://localhost:1337' : `https://api.${window.location.hostname}`;

const API = {
    URL: API_URL,
    CONFIGURATION_PATH: '/api/configuration/?populate=*',
    MEDIA_HUB_NAV_PATH: '/api/media-hub-categories/?populate[children][populate][video][populate]=*&filters[parent]$null&sort=title&pagination[pageSize]=100',
    MEDIA_HUB_ITEMS_PATH: '/api/media-hub-items?sort=title&populate[image]=*&populate[elements][populate]=*&pagination[pageSize]=100&filters[category][id][$eq]=',
    MEDIA_HUB_ITEM_PATH: '/api/media-hub-items/',
    MEDIA_HUB_ITEM_PARAMS: '?populate[image]=*&populate[elements][populate]=*&pagination[pageSize]=100',
    NEWS_NAV_PATH: '/api/news-categories/?populate[items][populate]=*&pagination[pageSize]=100&filters[published][$eq]=true&sort[1]=title%3Adesc',
    NEWS_ITEM_PATH: '/api/news-items/?populate[elements][populate][media_hub_items][populate][category][populate]=*&populate[elements][populate][media_hub_items][populate][image][populate]=*&populate[elements][populate][image][populate]=*&populate[elements][populate][videoFile][populate]=*&populate[elements][populate][pdfFile][populate]=*&populate[elements][populate][coverImage][populate]=*&pagination[pageSize]=100&filters[id][$eq]=',
}

const CONFIGURATION = {
    LOAD: 'CONFIGURATION_LOAD',
    LOAD_SUCCESS: 'CONFIGURATION_LOAD_SUCCESS',
    LOAD_ERROR: 'CONFIGURATION_LOAD_ERROR',
}

const NEWS = {
    LOAD_NAV: 'NEWS_LOAD_NAV',
    LOAD_NAV_SUCCESS: 'NEWS_LOAD_NAV_SUCCESS',
    LOAD_NAV_ERROR: 'NEWS_LOAD_NAV_ERROR',
    LOAD_CONTENT: 'NEWS_LOAD_CONTENT',
    LOAD_CONTENT_SUCCESS: 'NEWS_LOAD_CONTENT_SUCCESS',
    LOAD_CONTENT_ERROR: 'NEWS_LOAD_CONTENT_ERROR',
}

const MEDIA_HUB = {
    LOAD_NAV: 'MEDIA_HUB_LOAD_NAV',
    LOAD_NAV_SUCCESS: 'MEDIA_HUB_LOAD_NAV_SUCCESS',
    LOAD_NAV_ERROR: 'MEDIA_HUB_LOAD_NAV_ERROR',
    LOAD_CONTENT: 'MEDIA_HUB_LOAD_CONTENT',
    LOAD_CONTENT_SUCCESS: 'MEDIA_HUB_LOAD_CONTENT_SUCCESS',
    LOAD_CONTENT_ERROR: 'MEDIA_HUB_LOAD_CONTENT_ERROR',
    LOAD_ITEM: 'MEDIA_HUB_LOAD_ITEM',
    LOAD_ITEM_SUCCESS: 'MEDIA_HUB_LOAD_ITEM_SUCCESS',
    LOAD_ITEM_ERROR: 'MEDIA_HUB_LOAD_ITEM_ERROR',
}

const LOADING = {
    START: 'LOADING_START',
    SUCCESS: 'LOADING_SUCCESS',
    ERROR: 'LOADING_ERROR',
}

export { API, CONFIGURATION, LOADING, MEDIA_HUB, NEWS };
