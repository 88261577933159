import axios from "axios";
import {call, put, takeEvery} from 'redux-saga/effects';
import {API, NEWS} from "../../constants";
import {startLoading, stopLoading} from "../../loading/actions";

axios.defaults.headers.common['accept'] = `application/json`;

function* loadTopics(action) {
    try {
        yield put(startLoading());
        const topics = yield call(axios, `${API.URL}${API.NEWS_NAV_PATH}`);
        const {data} = topics;
        yield put({type: NEWS.LOAD_NAV_SUCCESS, payload: data});
        yield put(stopLoading());
    } catch(e) {
        yield put({type: NEWS.LOAD_NAV_ERROR});
        yield put(stopLoading(e));
    }
}

function* loadContent(action) {
    const {payload: path} = action;
    try {
        yield put(startLoading());
        const topics = yield call(axios, `${API.URL}${API.NEWS_ITEM_PATH}${path}`);
        const {data} = topics;
        yield put({type: NEWS.LOAD_CONTENT_SUCCESS, payload: data});
        yield put(stopLoading());
    } catch(e) {
        yield put({type: NEWS.LOAD_CONTENT_ERROR});
        yield put(stopLoading(e));
    }
}

function* initWatchers() {
    yield takeEvery(NEWS.LOAD_NAV, loadTopics);
    yield takeEvery(NEWS.LOAD_CONTENT, loadContent);
}

export default initWatchers;
