import { Link } from 'react-router-dom';
import styled from 'styled-components';

// TODO: Add breakpoints

const HeaderButton = ({ children, to, isSelected, onMouseOver }) => (
  <Button to={to} isselected={`${isSelected}`} onMouseOver={onMouseOver}>
    <Inner isselected={`${isSelected}`}>
      {children}
    </Inner>
  </Button>
);

const Inner = styled.div`
  display: flex;
  background-color: ${props => props.isselected === "true" ? "hsla(0,0%,100%,.4)" : "#ffffff00"};
  width: 100%;
  height: ${props => props.isselected === "true" ? "30vh" : "28vh"};
  backdrop-filter: ${props => props.isselected === "true" ? "blur(5px)" : "blur(0px)"};
  justify-content: center;
  align-items: center;
  transition: 0.1s linear;
  font-weight: normal;
  text-transform: uppercase;
  
  strong {
    font-weight: bolder;
  }
`;

const Button = styled(Link)`
  //display: inline-flex;
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;  
  justify-content: flex-end;
  height: 100vh;
  text-decoration: none;
  font-size: ${props => props.isselected === "true" ? "70px" : "50px"};
  color: white;
  font-family: "AlternateGotNo2D";
  transition: 0.1s linear;
`;


export default HeaderButton;
