import {Outlet, ScrollRestoration} from "react-router-dom";
import styled from "styled-components";
import ColoredDivider from "../../components/section-header/colored-divider";

const Layout = () => {
    return (
        <div>
            <Outlet/>
            <ColoredDivider />
            <Footer>
                <img src="/images/sanofi_regeneron_footer_2022.png" alt="Sanofi Regeneron Logo"/>
                <img src="/images/dupixent_logo_black.png" alt="Dupixent Logo"/>
            </Footer>
            <ScrollRestoration />
        </div>
    )
}

const Footer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 1rem 2rem 6rem 2rem;
  background-color: #eee;
  
  img {
    height: 40px;
  }
`;

export default Layout;
