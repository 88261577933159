import {Fragment, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Link, useNavigate, useParams} from "react-router-dom";
import styled from "styled-components";
import Center from '../../components/center';
import Layout from "../../components/layout";
import Markdown from "../../components/markdown";
import LinkRenderer from "../../components/markdown/LinkRenderer";
import MediaContent from "../../components/media-hub/media-content";
import NewsContent from "../../components/news/news-content";
import SectionHeader from "../../components/section-header";
import SideBar from "../../components/side-bar/";
import useImagePreloader from "../../hooks/useImagePreloader";
import {loadItem} from "../../store/media-hub/actions";
import {loadContents, loadNavigation} from "../../store/news/actions";

const imageSrcList = [
    "/images/news-image.jpg",
]

const News = () => {

    const {imagesLoaded} = useImagePreloader(imageSrcList)
    const dispatch = useDispatch();

    const navigate = useNavigate();
    const {sectionId, detailsId, mediaId} = useParams();

    const {items, initialSectionId, content, mediaContent} = useSelector(state => {
        const {news: {items, initialSectionId, content}, mediaHub: {item: mediaContent}} = state;
        return {items, initialSectionId, content, mediaContent};
    });

    const setPath = (selected) => {
        navigate(`/update/${selected[0]}/${selected[1]}#content`);
    }

    useEffect(() => {
        dispatch(loadNavigation())
    }, []);

    useEffect(() => {
        if (detailsId) {
            dispatch(loadContents([detailsId]));
        }
        if (mediaId) {
            dispatch(loadItem(mediaId));
        }
    }, [items, sectionId, detailsId, mediaId])

    let item = null;
    let itemContent = null;
    let firstDetailId = null;
    if (items) {
        item = items.find(item => item.id === (sectionId || initialSectionId));
        if(item && item.children.length) {
            const {children} = item;
            firstDetailId = children[0].id;
        }
    }
    if (item) {
        itemContent = item.content;
    }

    return (
        <Center hidden={!imagesLoaded}>
            <SectionHeader>
                <img src="/images/news-image.jpg" alt="Immpulse Header Image"/>
            </SectionHeader>
            <div style={{backgroundColor: 'red'}} id="content" />
            <Layout hidden={!items} sideBar={
                <SideBar items={items}
                         selected={[sectionId || initialSectionId, detailsId || ""]}
                         setSelected={setPath}
                         initialChildSelected={false}
                />
            }>
                {
                    !detailsId ?
                        <Fragment>
                            <Markdown components={{a: LinkRenderer}}>{itemContent}</Markdown>
                            { firstDetailId ? <GoToDetails to={`/update/${sectionId || initialSectionId}/${firstDetailId}/#content`}>EXPLORE MORE</GoToDetails> : null }
                        </Fragment>
                        : content ? (
                            <Fragment>
                                <NewsContent content={content} path={`/update/${sectionId}/${detailsId}/`}/>
                            </Fragment>
                        ) : null
                }
                {
                    mediaId && mediaContent ?
                        <MediaContent
                            closePath={`/update/${sectionId || initialSectionId}/${detailsId}`}
                            content={mediaContent}/>
                        : null
                }
            </Layout>
        </Center>
    )
}

const GoToDetails = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "AlternateGotNo2D", sans-serif;
  font-size: 1.1rem;
  font-weight: 400;
  width: 200px;
  background-color: #009b77;
  color: white;
    //color: ${props => props.selected ? 'white' : '#796e65'};
  text-decoration: none;
  text-transform: uppercase;
  border-radius: 10px;
  padding: 0.8rem 0 0.5rem 0;
  margin: 2rem 0;
  text-align: center;
  cursor: pointer;

  &:hover {
    background-color: #009b77;
    color: white;
  }
`;


export default News;
