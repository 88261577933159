import axios from "axios";
import {useEffect, useRef, useState} from "react";
import {Outline} from "react-pdf";
import {Document, Page} from "react-pdf/dist/esm/entry.webpack";
import styled from "styled-components";

const PDF = ({url}) => {
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [data, setData] = useState(null);
    const pdfRef = useRef();

    useEffect(() => {
        const downloadPDF = async () => {
            try {
                const response = await axios({url, method: 'GET', responseType: 'blob'})
                const {data} = response;
                setData(data);
            } catch (err) {
                console.log(err);
            }
        }
        downloadPDF();
    }, [])
    const onDocumentLoaded = ({numPages}) => {
        setNumPages(numPages);
    }

    const next = () => {
        setPageNumber(pageNumber < numPages ? pageNumber + 1 : numPages)
    }

    const prev = () => {
        setPageNumber(pageNumber > 1 ? pageNumber - 1 : 1)
    }
    //if (!data) return null;

    const requestFullScreen = () => {
        //pdfRef.current.requestFullscreen();
    }

    const onOutlineClick = ({ dest, pageIndex, pageNumber }) => {
        console.log(pageNumber)
        setPageNumber(pageNumber)
    }


    return (
        <Viewer onClick={requestFullScreen} ref={pdfRef} width="100%">
            <Document
                file={data}
                onLoadSuccess={onDocumentLoaded}
                options={{
                    cMapUrl: 'cmaps/',
                    cMapPacked: false,
                }}
                renderMode="canvas">
                <Outline onItemClick={onOutlineClick}/>
                <Page pageNumber={pageNumber} renderTextLayer={false} renderAnnotationLayer={false}/>
            </Document>
            <Toolbar>
                <Button className="fa fa-chevron-left" onClick={prev}></Button>
                <PageNumber>Page {pageNumber} of {numPages}</PageNumber>
                <Button className="fa fa-chevron-right" onClick={next}></Button>
            </Toolbar>
        </Viewer>
    )
}

const Viewer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ddd;
  padding: 10px;
  gap: 10px;
  border-radius: 10px;
  aspect-ratio: 9 / 11;
  margin-bottom: 1rem;
`;

const PageNumber = styled.p`
  font-family: "AlternateGotNo2D", sans-serif;
  font-size: 1.1rem;
  font-weight: 400;
  text-transform: uppercase;
  color: #796e65;
`;

const Toolbar = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;

const Button = styled.button`
  background-color: #009b77;
  color: white;
  padding: .75rem;
  border-radius: 10px;
  margin: 0;
  display: inline-block;
  width: 60px;
  border: 0;
`;


const Icon = styled.i`

`;

export default PDF;
