import {NEWS} from '../../constants/index';

const STATE = {
    items: [],
    initialSectionId: null,
    content: null,
}

const mapNavChildren = (payload, secondary = false) => {
    const { data } = payload;
    return data.map(item => {
        const {id, attributes: { title, description: content, items }} = item;
        return secondary ? { id: `${id}`, title } : { id: `${id}`, title, content, children: mapNavChildren(items, true) };
    })
}

const mapContent = (payload) => {
    const { data } = payload;
    console.log(data);
    if(data.length) {
        const { attributes: { title, elements } } = data[0];
        return {
            title,
            elements: mapElements(elements),
        }
    }
    return null;
}

const mapElements = (elements) => {
    return elements.map(element => {
        const { __component } = element;
        switch(__component) {
            case 'elements.rich-text':
                const { text } = element;
                return {
                    type: 'text',
                    text,
                }
            case 'elements.media-hub-relation':
                const { media_hub_items: { data: items } } = element;
                return {
                    type: 'mediahub-items',
                    items: mapMediaHubItems(items),
                }
            case 'elements.image':
                const { image: { data: { attributes: { url: imageUrl }}}} = element;
                return {
                    type: 'image',
                    url: imageUrl,
                }
            case 'elements.video':
                const { coverImage: { data: { attributes: { url: coverUrl } } }, videoFile: { data: { attributes: { url: videoUrl } } } } = element;
                return {
                    type: 'video',
                    url: videoUrl,
                    cover: coverUrl,
                }
            case 'elements.external-link':
                const { title, url } = element;
                return {
                    type: 'link',
                    title,
                    url,
                }
            case 'elements.pdf':
                const { title: pdfTitle, pdfFile: { data: { attributes: { size, url: pdfUrl } } } } = element;
                return {
                    type: 'pdf',
                    title: pdfTitle,
                    url: pdfUrl,
                    size: (size / 1000).toFixed(2),
                }
            default:
                return null;
        }
    });
}

const mapMediaHubItems = (items) => {
    return items.map(item => {
        const { id, attributes: { category, title, locked, image: { data: { attributes: { formats: { small: { url: thumbUrl }}}}} } } = item;
        const { data: { id: categoryId, attributes: { parent: { data: { id: parentCategoryId } } } } } = category;
        return {
            title,
            locked,
            image: thumbUrl,
            id: `${id}`,
            url: `${id}`,
        }
    });
}

function news(state = STATE, action) {
    const {type, payload} = action;
    switch (type) {
        case NEWS.LOAD_NAV_SUCCESS:
            const transformed = mapNavChildren(payload);
            if(transformed.length) {
                const {id: initialSectionId} = transformed[0];
                return {...state, items: transformed, initialSectionId};
            }
            break;
        case NEWS.LOAD_CONTENT_SUCCESS:
            const content = mapContent(payload);
            return {...state, content: content};
        default:
            return state;
    }
}

export default news;
