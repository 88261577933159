import {MEDIA_HUB} from '../../constants/index';

const STATE = {
    items: null,
    initialSectionId: null,
    initialItemId: null,
    content: null,
    item: null,
}

const mapNavChildren = (payload, secondary = false) => {
    const { data } = payload;
    return data.map(item => {
        const {id, attributes: { title, video, children}} = item;
        if(video) {
            const { videoFile: { data: { attributes: { url: videoUrl } } }, coverImage: { data: { attributes: { formats: { large: { url: coverUrl } } } } }  } = video;
            return secondary ? { id: `${id}`, title, video: videoUrl, cover: coverUrl } : { id: `${id}`, title, children: mapNavChildren(children, true) };
        }
        return secondary ? { id: `${id}`, title, video } : { id: `${id}`, title, children: mapNavChildren(children, true) };
    })
}

const mapItems = (payload) => {
    const { data } = payload;
    return data.map(item => {
        const { id, attributes: { title, description, locked, image: { data: { attributes: { url: image, formats: { small: { url: thumbnail } } } } }, elements } } = item;
        return {
            id: `${id}`,
            title,
            description,
            image,
            thumbnail,
            locked,
            elements: mapElements(elements)
        }
    })
}

const mapElements = (elements) => {
    return elements.map(element => {
        const { __component } = element;
        switch(__component) {
            case 'elements.external-link':
                const { title, url } = element;
                return {
                    type: 'link',
                    title,
                    url,
                }
            case 'elements.video':
                const { videoFile: { data: { attributes: { url: videoUrl } } }, coverImage: { data: { attributes: { formats: { large: { url: coverUrl } } } } }  } = element;
                return {
                    type: 'video',
                    url: videoUrl,
                    cover: coverUrl,
                }
            default:
                break;
        }
    })
}

const mapItem = (payload) => {
    const { data: { attributes: { title, description, locked, image: { data: { attributes: { url: image, formats: { small: { url: thumbnail } } } } }, elements } } } = payload;
    return {
        title,
        description,
        image,
        thumbnail,
        locked,
        elements: mapElements(elements)
    }
}

function mediaHub(state = STATE, action) {
    const {type, payload} = action;
    switch (type) {
        case MEDIA_HUB.LOAD_NAV:
            return {...state, items: null, initialSectionId: null, initialItemId: null, content: null};
        case MEDIA_HUB.LOAD_NAV_SUCCESS:
            const transformed = mapNavChildren(payload);
            const {id: initialSectionId, children} = transformed[0];
            if(transformed.length) {
                if(children.length) {
                    const {id: initialItemId} = children[0];
                    return {...state, items: transformed, initialSectionId, initialItemId};
                }
                return {...state};
            }
            return {...state};
        case MEDIA_HUB.LOAD_CONTENT:
            return {...state, content: null}
        case MEDIA_HUB.LOAD_CONTENT_SUCCESS:
            const items = mapItems(payload);
            return {...state, content: items};
        case MEDIA_HUB.LOAD_ITEM:
            return {...state, item: null}
        case MEDIA_HUB.LOAD_ITEM_SUCCESS:
            const item = mapItem(payload);
            return {...state, item};
        default:
            return state;
    }
}

export default mediaHub;
