import styled from 'styled-components';

const ColoredDivider = styled.div`
  position: relative;  
  width: 100%;
  height: 14px;
  background: url('/images/news-line.png');
  background-size: 100% 100%;
`

export default ColoredDivider
