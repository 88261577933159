import { useEffect, useState } from 'react'

const preloadImage = (src) => {
    return new Promise((resolve, reject) => {
        const img = new Image()
        img.onload = function() {
            resolve(img)
        }
        img.onerror = img.onabort = function() {
            reject(src)
        }
        img.src = src
    })
}

const useImagePreloader = (imageList) => {
    const [imagesLoaded, setImagesLoaded] = useState(false)

    useEffect(() => {
        let isCancelled = false

        async function effect() {
            if (isCancelled) {
                return
            }

            const imagesPromiseList = []
            for (const i of imageList) {
                imagesPromiseList.push(preloadImage(i))
            }

            await Promise.all(imagesPromiseList)

            if (isCancelled) {
                return
            }

            setImagesLoaded(true)
        }

        effect();

        return () => {
            isCancelled = true
        }
    }, [imageList])

    return { imagesLoaded }
}

export default useImagePreloader;
