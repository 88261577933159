import {Fragment, useEffect, useRef, useState} from "react";
import styled, {keyframes} from "styled-components"
import sideBar from "../side-bar";
import BackButton from "./back-button";
import breakpoint from 'styled-components-breakpoint';

const Layout = ({children, sideBar, hidden}) => (
    <FullWidth>
        <StyledLayout hidden={hidden}>
            <Content>
                <SideBar>
                    {sideBar}
                </SideBar>
                <ContentArea>
                    {children}
                </ContentArea>
            </Content>
        </StyledLayout>
    </FullWidth>
)

const FullWidth = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: white;
`;

const Content = styled.div`
  display: flex;
  flex-direction: row;
`;

const SideBar = styled.div`
  flex-direction: column;
  flex: 0.25;
  animation-duration: 1s;
`;

const ContentArea = styled.div`
  flex: 0.75;
  padding-top: 3rem;
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
`;

const StyledLayout = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 700px;
  ${breakpoint('lg')` // 24
    max-width: 900px;
  `}
  ${breakpoint('xl')` // 24
    max-width: 1000px;
  `}
  padding: 3rem 0;
  visibility: ${props => props.hidden ? 'hidden' : 'visible'};
  animation: ${props => props.hidden ? fadeOut : fadeIn} .2s linear;
  transition: visibility .2s linear;
`;


export default Layout;
