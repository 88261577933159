import {NEWS} from "../../constants";

const loadNavigation = () => (
    {
        type: NEWS.LOAD_NAV,
    }
)

const loadContents = (sections) => (
    {
        type: NEWS.LOAD_CONTENT,
        payload: sections,
    }
)

export {loadNavigation, loadContents};
