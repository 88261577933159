import axios from "axios";
import {call, put, takeEvery} from 'redux-saga/effects';
import {API, CONFIGURATION} from "../../constants";
import {startLoading, stopLoading} from "../../loading/actions";

axios.defaults.headers.common['accept'] = `application/json`;

function* loadConfiguration(action) {
    try {
        yield put(startLoading());
        const topics = yield call(axios, `${API.URL}${API.CONFIGURATION_PATH}`);
        const {data} = topics;
        yield put({type: CONFIGURATION.LOAD_SUCCESS, payload: data});
        yield put(stopLoading());
    } catch(e) {
        console.log(e);
        yield put({type: CONFIGURATION.LOAD_ERROR});
        yield put(stopLoading(e));
    }
}


function* initWatchers() {
    yield takeEvery(CONFIGURATION.LOAD, loadConfiguration);
}

export default initWatchers;
