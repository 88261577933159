import {useEffect, useState} from 'react';
import {useDispatch} from "react-redux";
import Header from './components/header';
import HeaderButton from './components/header/header-button';
import {loadConfiguration} from "./store/configuration/actions";

const App = () => {
    const images = ["/images/bg-mediahub.jpg", "/images/bg-diary.jpg", "/images/bg-networking.jpg"]
    const [selected, setSelected] = useState(0);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(loadConfiguration())
    }, []);

    return (<div>
        <Header images={images} selected={selected}>
            <HeaderButton
                to="/mediahub"
                onMouseOver={() => setSelected(0)}
                isSelected={selected === 0}>
                Media hub
            </HeaderButton>
            <HeaderButton
                to="/update"
                onMouseOver={() => setSelected(1)}
                isSelected={selected === 1}>
                <strong>Imm</strong>pulse
            </HeaderButton>
            <HeaderButton
                to="/networking"
                onMouseOver={() => setSelected(2)}
                isSelected={selected === 2}>
                Networking
            </HeaderButton>
        </Header>
    </div>);
}

export default App;
