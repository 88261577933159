import {Fragment, useState} from "react";
import {Document} from "react-pdf";
import {Link} from "react-router-dom";
import styled from "styled-components";
import breakpoint from "styled-components-breakpoint";
import {API} from "../../../store/constants";
import Markdown from "../../markdown";
import LinkRenderer from "../../markdown/LinkRenderer";
import PDF from "../../pdf";

const NewsContent = ({content, path, closePath}) => {
    const {title, elements} = content;
    return (
        <Content>
            {
                elements.map((element, i) => <RenderElement element={element} path={path} key={i}/>)
            }
        </Content>
    )
}

const Content = styled.div`
  flex-direction: column;
`;

const RenderElement = ({element, path}) => {
    const [selected, setSelected] = useState(null);

    const {type, url, title, text, items, cover, size} = element;
    switch (type) {
        case "text":
            return (
                <Markdown components={{a: LinkRenderer}}>{text}</Markdown>
            )
        case "mediahub-items":
            return <Grid key={element}>
                {
                    items.map(item => {
                        const {id, image, title, url, locked} = item;


                        return (
                            !locked ?
                                <Item key={image}
                                      preventScrollReset={true}
                                      to={`${path}${id}`}
                                      onMouseOver={() => setSelected(image)}
                                      onMouseOut={() => setSelected(null)}
                                >
                                    <ImageContainer selected={selected === image}>
                                        <Image key={image} src={`${API.URL}${image}`}/>
                                    </ImageContainer>
                                    <Text>{title}</Text>
                                </Item> :
                                <NonClickable key={image}>
                                    <ImageContainer selected={selected === image}>
                                        <Image key={image} src={`${API.URL}${image}`} dimmed/>
                                        <PadLock className="far fa-lock"/>
                                    </ImageContainer>
                                    <Text>{title}</Text>
                                </NonClickable>
                        )
                    })
                }
            </Grid>
        case "image":
            return <FullImage src={`${API.URL}${url}`} key={url}/>
        case "video":
            return (
                <Video src={`${API.URL}${url}`}
                       controls
                       poster={`${API.URL}${cover}`}
                       key={url}>
                    Sorry, your browser doesn't support embedded videos,
                    but don't worry, you can <a href={url}>download it</a>
                    and watch it with your favorite video player!
                </Video>
            )
        case "link":
            return (
                <ExternalLink href={url} key={url} target="_blank"><Icon className="far fa-link"/> {title}
                </ExternalLink>
            )
        case "pdf":
            return (
                <Fragment key={url}>
                    <PDF url={`${API.URL}${url}`} />
                    <ExternalLink
                        href={`${API.URL}${url}`}
                        target="_blank"
                    >
                        <Icon className="far fa-download"/> {title} ({size} MB)
                    </ExternalLink>
                </Fragment>
            )
        default:
            return null;
    }
}

const Video = styled.video`
  display: flex;
  width: 100%;
  border-radius: 10px;
  margin: 0 0 1.5rem 0;
`;

const ExternalLink = styled.a`
  display: flex;
  align-items: center;
  gap: 0.25rem;
  font-family: "AlternateGotNo2D", sans-serif;
  font-size: 1.1rem;
  font-weight: 400;
  text-transform: uppercase;
  margin-bottom: 1.5rem;
`;

const Icon = styled.i`
  background-color: #009b77;
  color: white;
  padding: .5rem;
  border-radius: 1rem;
  margin-right: .25rem;
`;

const PadLock = styled.i`
  position: absolute;
  font-size: 1.1rem;
  border-radius: 3rem;
  background-color: #ffffffee;
  padding: 1rem;
`;

const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 15px 15px;
  margin-bottom: 1.5rem;
`;

const Item = styled(Link)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  box-sizing: border-box;
  width: calc(50% - 10px);
  ${breakpoint('xl')` // 24
    width: calc(33.3333333% - 10px);
  `}
`;

const NonClickable = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  box-sizing: border-box;
  width: calc(50% - 10px);
  ${breakpoint('xl')` // 24
    width: calc(33.3333333% - 10px);
  `}
  color: #796e65;
`;

const ImageContainer = styled.div`
  position: relative;
  aspect-ratio: 4 / 3;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex: 1;
  border-radius: 10px;
  overflow: hidden;
  background-color: #dddddd;
  padding: ${props => props.selected ? "0.25rem" : "0.75rem"};
  transition: 0.25s ease-out;
`;

const Image = styled.div`
  background: url('${props => props.src}');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  opacity: ${props => props.dimmed ? 0.25 : 1};
  width: 100%;
  height: 100%;
  transition: 0.25s ease-out;
`;

const FullImage = styled.img`
  max-width: 100%;
  border-radius: 10px;
  margin: 0 0 1.5rem 0;
  //padding: 0 4rem 0 0;
  //transition: 0.25s ease-out;
`;

const Text = styled.p`
  font-family: "AlternateGotNo2D", sans-serif;
  font-size: 1.1rem;
  font-weight: 400;
  text-transform: uppercase;
  text-align: center;
  margin: 0.5rem 0 0 0;
`;

export default NewsContent;
