import {Fragment} from "react";
import styled from 'styled-components';

// TODO: Add breakpoints

const BackgroundImage = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-image: url(${props => props.image});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    opacity: ${props => props.selected ? 1 : 0};
    transition: 0.1s linear;
  `;

const StyledHeader = ({ className, images, children, selected }) => (
  <div className={className}>
    {images.map((image, index) => (
      <BackgroundImage image={image} key={image} selected={index === selected} />
    ))}
    {children}
  </div>
)

const Header = styled(StyledHeader)`
  position: relative;  
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: row;
  justify-content: ${props => props.right ? "flex-end" : "flex-start"};
  align-items: flex-start;
`;

export default Header;
